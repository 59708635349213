import { authConstants } from '../constant/auth'
import { writeLocalStorageData, deleteLocalStorageData, readLocalStorageData } from '../helper/localStorage'
import authService from '../service/auth'
import mainLayoutActions from '../action/mainLayout'
import authHelper from '../helper/auth'
import axiosCommon from '../helper/axiosCommon'



function resetPassword(data) {
  const request = () => ({ type: authConstants.RESETPASSWORD_REQUEST })
  const success = resp => ({ type: authConstants.RESETPASSWORD_SUCCESS, resp })
  const failure = () => ({ type: authConstants.RESETPASSWORD_FAILURE })
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await authService.resetPassword(data)
      dispatch(mainLayoutActions.successSnackbar('Remipostazione password effettuata.'))
      dispatch(success(resp))
    }
    catch (error) {
      dispatch(mainLayoutActions.errorSnackbar('Remipostazione password fallita. ' + axiosCommon.getFormattedError(error)))
      dispatch(failure())
      throw error
    }
  }
}


function sendResetPasswordLink(data) {
  const request = () => ({ type: authConstants.SENDRESETLINK_REQUEST })
  const success = resp => ({ type: authConstants.SENDRESETLINK_SUCCESS, resp })
  const failure = () => ({ type: authConstants.SENDRESETLINK_FAILURE })
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await authService.sendResetPasswordLink(data)
      dispatch(mainLayoutActions.successSnackbar('Invio link effettuato.'))
      dispatch(success(resp))
    }
    catch (error) {
      dispatch(mainLayoutActions.errorSnackbar('Invio link fallito. ' + axiosCommon.getFormattedError(error)))
      dispatch(failure())
      throw error
    }
  }
}


function logout() {
  const request = () => ({ type: authConstants.LOGOUT_REQUEST })
  const success = resp => ({ type: authConstants.LOGOUT_SUCCESS, resp })
  const failure = () => ({ type: authConstants.LOGOUT_FAILURE })
  return async dispatch => {
    dispatch(request())
    try {
      const data = readLocalStorageData()
      if (data !== null && data.access_token) {
        const decodedToken = authHelper.decodeToken(data.access_token)
        const request = { jti: decodedToken.jti }
        /* const resp = */ await authService.logout(request)
      }
      dispatch(mainLayoutActions.successSnackbar('Logout effettuato.'))
      dispatch(success(/*resp*/))
    }
    catch (error) {
      dispatch(mainLayoutActions.errorSnackbar('Logout effettuato. ' + axiosCommon.getFormattedError(error)))
      dispatch(failure())
      // throw error // keep commented so the user can logout anyway
    }
    finally {
      deleteLocalStorageData()
    }
  }
}


function login(data) {
  const request = () => ({ type: authConstants.LOGIN_REQUEST })
  const success = resp => ({ type: authConstants.LOGIN_SUCCESS, resp })
  const failure = () => ({ type: authConstants.LOGIN_FAILURE })
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await authService.login(data)
      try {
        authHelper.verifyToken(resp.access_token) // returns verified and decoded token
        writeLocalStorageData(resp)
        dispatch(mainLayoutActions.successSnackbar('Login effettuato.'))
        dispatch(success(resp))
      }
      catch (verifyError) {
        console.log(verifyError)
        throw new Error('Il token non è valido.')
      }
    }
    catch (error) {
      dispatch(mainLayoutActions.errorSnackbar('Accesso Negato. ' + axiosCommon.getFormattedError(error)))
      dispatch(failure())
      throw error
    }
  }
}



export default {
  resetPassword,
  sendResetPasswordLink,
  logout,
  login
}
