import axiosCommon from '../helper/axiosCommon'
import qs from 'qs'


async function updateOne(application) {
  const req = axiosCommon.getAxiosParamsAuth(`/applications/${application.id}`, 'put', application)
  return await axiosCommon.axiosRequest(req)
}

async function deleteOne(id) {
  const req = axiosCommon.getAxiosParamsAuth(`/application/${id}`, 'delete')
  return await axiosCommon.axiosRequest(req)
}

async function createOne(application) {
  const req = axiosCommon.getAxiosParamsAuth('/applications', 'post', application)
  return await axiosCommon.axiosRequest(req)
}

async function readPaginated(query) {
  const req = axiosCommon.getAxiosParamsAuth('/applications?' + qs.stringify(query), 'get')
  return await axiosCommon.axiosRequest(req)
}

async function readList() {
  const req = axiosCommon.getAxiosParamsAuth('/applications-list', 'get')
  return await axiosCommon.axiosRequest(req)
}

async function readDashboard(appId) {
  const req = axiosCommon.getAxiosParamsAuth(`/applications-dashboard?appId=${appId}`, 'get')
  return await axiosCommon.axiosRequest(req)
}

export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
  readList,
  readDashboard
}


