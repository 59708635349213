import React from 'react'
import PageLayout from '../PageLayout'
import GeometriesTable from './GeometriesTable'

// const useStyles = makeStyles(theme => ({
//     content: {
//        //
//     },
// }))


export default function AppGeometriesPage() {
    // const classes = useStyles()

    console.log('AppGeometriesPage render')

    return (
        <PageLayout>
            <GeometriesTable />
        </PageLayout>
    )
}

