import axios from 'axios'
import { readLocalStorageData, /*deleteLocalStorageData*/ } from '../helper/localStorage'



function downloadFileFromBlob(response, filename) {
  const url = window.URL.createObjectURL(new Blob([response]))
  const link = document.createElement('a')
  link.href = url;
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

function getAxiosParamsAuth(url, method, data) {
  const req = getAxiosParams(url, method, data)
  const userData = readLocalStorageData()
  req.headers['Authorization'] = 'Bearer ' + userData.access_token
  return req
}

function getAxiosParams(url, method, data) {
  const params = {
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    url,
    method,
    headers: { 'Accept': 'application/json' }
  }
  if (data)
    params.data = data
  return params
}

function getFormattedError(error) {
  if (error.response) {
    /*console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)*/
    if (error.response.data.error) {
      return error.response.data.error
    }
    else if (error.response.data.errors) {
      return Object.keys(error.response.data.errors).reduce((sum, val) => sum += error.response.data.errors[val].join(', ') + ' ', '')
    }
    else if (error.response.data.message) {
      return error.response.data.message
    }
    else {
      return JSON.stringify(error.response.data)
    }
  } else if (error.request) {
    // console.log(error.request);
    return 'Server non raggiungibile.'
  } else {
    // console.log('Error', error.message)
    return error.message
  }
  //console.log(error)  
}


async function axiosRequest(req) {
  try {
    const res = await axios.request(req)
    return res.data
  }
  catch (err) {
    if (err.response && err.response.status === 401) { //Unauthorized
      // deleteLocalStorageData()
      // window.location.reload(false)
    }
    // if (err.response && err.response.status === 403 //Forbidden) { }
    // if (err.response && err.response.status === 503) { }
    throw err
  }
}

async function uiGetTimestamp(path) {
  const result = await axios.get(`${process.env.REACT_APP_FRONTEND_BASE_URL}/${path}?ts=${+ new Date()}`)
  return result.data
}

export default {
  downloadFileFromBlob,
  getAxiosParamsAuth,
  getAxiosParams,
  getFormattedError,
  axiosRequest,
  uiGetTimestamp
}
