import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'


const useStyles = makeStyles(theme => ({
    fab: {
        // backgroundColor: '#fff',
        position: 'fixed',
        right: 20,
        bottom: 20,
    }
}))


export default function FabButton(props) {
    const classes = useStyles()

    const { handleClick } = props

    return (
        <Fab color="primary" className={classes.fab} aria-label='add' onClick={handleClick}>
            <AddOutlinedIcon />
        </Fab>
    )
}
