import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import PageLayout from './PageLayout'


const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(4)
  },
}))


function AppCreatePage(props) {
  const classes = useStyles()

  // console.log('AppCreatePage render')

  return (
    <PageLayout mainDrawer={true} >
      <Box className={classes.content}>
        AppCreatePage
            </Box>
    </PageLayout>

  )
}

const mapStateToProps = store => {
  const { application, mainLayout } = store
  return {
    apps: application.list,
    permissionMap: mainLayout.readProfile.permissionMap
  }
}

export default connect(mapStateToProps)(AppCreatePage)
