import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline, Divider, Avatar } from '@material-ui/core'
import HeaderBar2 from "../component/layout/HeaderBar2"
import DrawerMenu2 from "../component/layout/DrawerMenu2"
import { routes, replaceAppId, replaceAppIdPlugId } from '../helper/routes'
import AppsIcon from '@material-ui/icons/Apps'
import DrawerMenu2Item from '../component/layout/DrawerMenu2Item'
// import DashboardIcon from '@material-ui/icons/Dashboard'
// import HomeIcon from '@material-ui/icons/Home'
import ExtensionIcon from '@material-ui/icons/Extension'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import ColorLensIcon from '@material-ui/icons/ColorLens'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import CategoryIcon from '@material-ui/icons/Category'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import ColorizeIcon from '@material-ui/icons/Colorize'
import FlareIcon from '@material-ui/icons/Flare'
import TextureIcon from '@material-ui/icons/Texture'
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting'
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LayersIcon from '@material-ui/icons/Layers'
import { useParams } from 'react-router-dom'
import DrawerMenu2SubItem from '../component/layout/DrawerMenu2SubItem'
import { connect } from 'react-redux'
import appPhImage from '../resource/app-placeholder.png'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
  },
  content: {
    marginTop: 64,
    flexGrow: 1,
    // overflow: 'auto',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
}))


function DrawerMain(props) {
  const { user } = props
  const { permissionMap } = user
  return (
    <DrawerMenu2>
      {(permissionMap['read-all'] || permissionMap['read-applications']) &&
        <DrawerMenu2Item title='Applications' route={routes.home} icon={<AppsIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-users']) &&
        <DrawerMenu2Item title='Users' route={routes.users} icon={<AccountCircleIcon />}>
          {(user.role.slug === 'superadmin') && [
            <DrawerMenu2SubItem key='role-nav' title='Roles' route={routes.roles} icon={<AccountTreeIcon fontSize="small" />} />,
            <DrawerMenu2SubItem key='perm-nav' title='Permissions' route={routes.permissions} icon={<VerifiedUserIcon fontSize="small" />} />
          ]}
        </DrawerMenu2Item>
      }
    </DrawerMenu2>
  )
}

function DrawerApp(props) {
  const classes = useStyles()
  const { appId, appMap, user } = props
  const { permissionMap } = user
  const app = appMap[appId] || {}
  return (
    <DrawerMenu2>
      {(permissionMap['read-all'] || permissionMap['read-applications']) &&
        <DrawerMenu2Item title={app.title} route={replaceAppId(routes.appHome, appId)}
          icon={<Avatar alt="app" className={classes.avatar} src={app.img_url ? app.img_url : appPhImage} /> /*<HomeIcon />*/} />
      }
      {/* {(permissionMap['read-all'] || permissionMap['read-statistics']) &&
        <DrawerMenu2Item title='Statistiche' route={replaceAppId(routes.appStatistics, appId)} icon={<EqualizerIcon />} />
      } */}
      {app.plugins &&
        app.plugins.map(p =>
          <DrawerMenu2Item key={p.id} title={p.title} route={replaceAppIdPlugId(routes.appPlugin, appId, p.route_id)} icon={<ExtensionIcon />} />)
      }
      {/* <Divider variant="middle" />
      {(permissionMap['read-all'] || permissionMap['read-compositions']) &&
        <DrawerMenu2Item title='Composizioni' route={replaceAppId(routes.appCompositions, appId)} icon={<FavoriteIcon />}>
          {(permissionMap['read-all'] || permissionMap['read-configuredproducts']) &&
            <DrawerMenu2SubItem title='Prodotti configurati' route={replaceAppId(routes.appConfiguredProducts, appId)} icon={<LoyaltyIcon fontSize="small" />} />
          }
          {(permissionMap['read-all'] || permissionMap['read-configuredparts']) &&
            <DrawerMenu2SubItem title='Parti configurate' route={replaceAppId(routes.appConfiguredParts, appId)} icon={<PermDataSettingIcon fontSize="small" />} />
          }
        </DrawerMenu2Item>
      }
      {(permissionMap['read-all'] || permissionMap['read-products']) &&
        <DrawerMenu2Item title='Prodotti' route={replaceAppId(routes.appProducts, appId)} icon={<LocalOfferIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-classparts']) &&
        <DrawerMenu2Item title='Classi parte' route={replaceAppId(routes.appClassparts, appId)} icon={<GroupWorkIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-instanceparts']) &&
        <DrawerMenu2Item title='Istanze parte' route={replaceAppId(routes.appInstanceparts, appId)} icon={<ScatterPlotIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-patternmixes']) &&
        <DrawerMenu2Item title='Patternmix' route={replaceAppId(routes.appPatternmixes, appId)} icon={<LayersIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-patterns']) &&
        <DrawerMenu2Item title='Pattern' route={replaceAppId(routes.appPatterns, appId)} icon={<ColorLensIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-colors']) &&
        <DrawerMenu2Item title='Colori' route={replaceAppId(routes.appColors, appId)} icon={<ColorizeIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-materials']) &&
        <DrawerMenu2Item title='Materiali' route={replaceAppId(routes.appMaterials, appId)} icon={<FlareIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-geometries']) &&
        <DrawerMenu2Item title='Geometrie' route={replaceAppId(routes.appGeometries, appId)} icon={<CategoryIcon />} />
      }
      {(permissionMap['read-all'] || permissionMap['read-maps']) &&
        <DrawerMenu2Item title='Mappe' route={replaceAppId(routes.appMaps, appId)} icon={<TextureIcon />} />
      } */}
      {(permissionMap['read-all'] || permissionMap['read-users']) &&
        <div>
          <Divider variant="middle" />
          <DrawerMenu2Item title='Users' route={replaceAppId(routes.appUsers, appId)} icon={<AccountCircleIcon />} />
        </div>
      }
    </DrawerMenu2>
  )
}


function PageLayout(props) {
  const classes = useStyles()
  const params = useParams();
  const { mainDrawer, children, user, appMap, overflow = true } = props

  // console.log('PageLayout render', mainDrawer)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HeaderBar2 />
      {mainDrawer ?
        <DrawerMain user={user} /> :
        <DrawerApp appId={params.appId} appMap={appMap} user={user} />
      }
      <div className={classes.content} style={{ overflow: overflow ? 'auto' : 'initial' }}>
        {children}
      </div>
    </div>
  )
}


const mapStateToProps = store => {
  const { mainLayout, application } = store
  return {
    appMap: application.appMap,
    user: mainLayout.readProfile/*.permissionMap*/,
  }
}

export default connect(mapStateToProps)(PageLayout)
