import { applicationConstants } from '../constant/application'
import applicationService from '../service/application'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: applicationConstants.UPDATE_APPLICATION_REQUEST})
  const success = resp => ({type: applicationConstants.UPDATE_APPLICATION_SUCCESS, resp})
  const failure = () => ({type: applicationConstants.UPDATE_APPLICATION_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await applicationService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Aggiornamento applicazione "${data.title}" effettuato.`))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Aggiornamento applicazione "${data.title}" fallito. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

function deleteOne(data) {
  // const request = () => ({type: applicationConstants.DELETE_APPLICATION_REQUEST})
  // const success = resp => ({type: applicationConstants.DELETE_APPLICATION_SUCCESS, resp})
  // const failure = () => ({type: applicationConstants.DELETE_APPLICATION_FAILURE})
  return async dispatch => {
    // dispatch(request())
    try {
      /*const resp = */await applicationService.deleteOne(data.id)
      // dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Eliminazione dati applicazione "${data.title}" effettuata.`))
      // dispatch(readPaginated())
    }
    catch (error) {
      // dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Eliminazione dati applicazione "${data.title}" fallita. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

function createOne(data) {
  const request = () => ({type: applicationConstants.CREATE_APPLICATION_REQUEST})
  const success = resp => ({type: applicationConstants.CREATE_APPLICATION_SUCCESS, resp})
  const failure = () => ({type: applicationConstants.CREATE_APPLICATION_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await applicationService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar(`Creazione applicazione "${data.title}" effettuata.`))
      dispatch(readPaginated())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar(`Creazione applicazione "${data.title}" fallita. ` + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

function readPaginated(query) {
  //const request = () => ({type: applicationConstants.READ_APPLICATIONS_REQUEST})
  const success = resp => ({type: applicationConstants.READ_APPLICATIONS_SUCCESS, resp})
  const failure = () => ({type: applicationConstants.READ_APPLICATIONS_FAILURE})
  return async dispatch => {
    //dispatch(request())
    try {
      const resp = await applicationService.readPaginated(query)
      dispatch(success(resp))
      return resp
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura applicazioni fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

function readList() {
  const request = () => ({type: applicationConstants.READ_APPLICATIONS_LIST_REQUEST})
  const success = resp => ({type: applicationConstants.READ_APPLICATIONS_LIST_SUCCESS, resp})
  const failure = () => ({type: applicationConstants.READ_APPLICATIONS_LIST_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await applicationService.readList()
      dispatch(success(resp))
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura applicazioni fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }
}

export default {
  updateOne,
  deleteOne,
  createOne,
  readPaginated,
  readList
}
