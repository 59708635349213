import React from 'react'
import { Provider } from "react-redux"
import { store } from "./helper/store"
import { updatedVersionCheck, startVersionCheckTask } from "./helper/version"
import MainRouter from "./component/route/MainRouter"
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'

updatedVersionCheck()
startVersionCheckTask()

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#1f87f0',
      light: '#4b9ff3',
      dark: '#155ea8',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1f87f0',
      light: '#4b9ff3',
      dark: '#155ea8',
      contrastText: '#fff',
    },
    // contrastThreshold: 3,
    // tonalOffset: 0.2,
  }
})

const snackbarStyles = {
  success: { color: '#fff' },
  error: { color: '#fff' },
  warning: { color: '#fff' },
  info: { color: '#fff' },
}

function App({ classes }) {

  // console.log('App render')

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={7}
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}>
          <MainRouter />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default withStyles(snackbarStyles)(App)
